<template>
    <el-drawer
        title="类目列表"
        :visible.sync="drawer"
        :size="600"
        direction="rtl"
        append-to-body
        @open="handleOpen"
        @close="closeNoticeMsgDialog">
        <el-divider class="h_divider"></el-divider>
        <el-form :inline="true" :model="form" size="small" class="form-inline">
            <el-form-item>
                <el-input v-model.trim="form.categoryName" clearable placeholder="品类名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search();">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-divider class="h_divider"></el-divider>
        <div class="main" v-loading="loading">
            <div class="content left_menu">
                <ul>
                    <li v-for="(item,index) in productTypeList" :key="index"
                        :class="selectMsg.id == item.id?'li_action':''" @click="selectItem(item)">{{ item.name }}
                    </li>
                </ul>
            </div>
            <el-divider direction="vertical" class="v_divider"></el-divider>
            <select-product-drawer @closeLoad="closeLoad" :searchSelectItemList="searchSelectItemList" :item="selectMsg"
                                   ref="selectProduct" class="right_menu"></select-product-drawer>
        </div>
    </el-drawer>
</template>

<script>

import {mapState, mapActions} from "vuex";
import selectProductDrawer from '@/views/product/views/basic/components/selectProductDrawer';

export default {

    components: {
        selectProductDrawer
    },

    computed: {
        ...mapState('product', ['addProductTypeDrawerVisible']),
    },

    watch: {

        addProductTypeDrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            }
        }

    },

    data() {

        return {

            drawer: false,

            loading: false,

            btnLoading: false,

            productTypeList: [],

            selectMsg: {
                id: "",
                name: "",
                isSearch: false
            },

            isSearch: false,

            searchSelectItemList: [],

            form: {
                categoryName: ""
            }

        }

    },

    methods: {

        ...mapActions('category', ['queryFirstLevelCategory', 'searchCategoryTree']),
        ...mapActions('product', ['closeAddProductTypeDrawer']),

        handleOpen() {
            this.loading = true;
            this.queryFirstLevelCategory().then(res => {
                this.loading = false;
                if (!res.result) return;
                let list = [];
                for (let i = 0; i < res.result.length; i++) {
                    let {name, code, children} = res.result[i];
                    let zhName = JSON.parse(name).zh;
                    list.push({name: zhName, id: code, isSearch: false, children: children ? children : []});
                }
                this.isSearch = false;
                this.productTypeList = list;
                console.log(list)
                this.selectItem(list[0])
            }, err => {
                this.loading = false;
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        getItemList(code) {
            let list = [];
            this.productTypeList.forEach(item => {
                if (item.id == code) {
                    console.log(item.id, code)
                    console.log(item);
                    list = item.children && item.children.length > 0 ? item.children : [];
                    return;
                }
            })
            return list;
        },

        search() {
            if (!this.form.categoryName) {
                this.searchSelectItemList = [];
                this.handleOpen();
                return;
            }
            this.isSearch = true;
            this.loading = true;
            this.searchCategoryTree({
                keyword: this.form.categoryName
            }).then(res => {
                if (!res.result) return;
                let list = [];
                for (let i = 0; i < res.result.length; i++) {
                    let {name, code, children} = res.result[i];
                    let zhName = JSON.parse(name).zh
                    list.push({name: zhName, id: code, isSearch: true, children: children ? children : []});
                }
                this.productTypeList = list;
                this.selectItem(this.productTypeList[0])
                console.log(this.productTypeList, "11111111111111111")
                this.searchSelectItemList = this.productTypeList[0].children.length > 0 ? this.productTypeList[0].children : [];
                console.log(this.searchSelectItemList, "585858585858585")
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
                this.btnLoading = false;
            })
        },

        closeNoticeMsgDialog() {
            this.closeAddProductTypeDrawer();
            this.selectMsg = {}
        },

        selectItem(item) {
            this.selectMsg = item;
            this.searchSelectItemList = this.isSearch ? this.getItemList(item.id) : [];
            this.loading = !this.isSearch;
            // this.$store.commit("product/SET_SELECT_PRODUCT_DRAWER_VISIBLE",true);
            // this.$store.commit("product/SET_SELECT_FIRST_CATEGORY",item);
            // this.$refs.selectProduct.handleOpen();
        },

        closeLoad() {
            this.loading = false;
        }

    }

}
</script>

<style scoped>
/deep/ .el-divider--horizontal {
    margin-top: 0px;
}

/deep/ .el-drawer__header {
    margin-bottom: 20px;
}

.main {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.form-inline {
    padding: 0;
    text-align: right;
    margin: 10px 0;
}

.form-inline .el-form-item {
    margin-bottom: 0;
}

.content {
    text-align: left;
}

.left_menu {
    width: 150px;
    height: calc(100% - 60px);
    float: left;
}

.right_menu {
    width: 400px;
    height: calc(100% - 60px);
    float: left;
}

.v_divider {
    float: left;
    height: calc(100% - 60px);
    margin-left: 0px;
}

.h_divider {
    height: 0.3px;
    margin: 0;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
    padding: 10px 26px;
    cursor: pointer;
}

.li_action {
    background-color: #1c204f;
    color: white !important;
}

li:hover {
    color: #7986ca;
}

</style>
