<template>
    <div>

        <el-form :disabled="isDisabled" :model="form" size="small" :rules="rules" ref="ruleForm" class="basic_form"
                 :label-width="formLabelWidth">

            <el-form-item label="PID：" style="margin-bottom: 0;" required>
                {{ form.pid }}
            </el-form-item>

            <el-form-item label="产品状态：" style="margin-bottom: 11px;" required>
                {{ $config.getMsgItemUtil($message.productStatusList, form.state, 'id', 'name') }}
            </el-form-item>

            <el-form-item label="产品型号：" prop="model">
                <el-input
                    v-model.trim="form.model"
                    placeholder="请输入型号">
                </el-input>
            </el-form-item>

            <el-form-item label="产品名称：" prop="productName">
                <el-input
                    v-model.trim="form.productName"
                    placeholder="请输入产品名称">
                </el-input>
            </el-form-item>

            <el-form-item label="产品类别：" required :error="err.productTypeErr">
                <el-button type="primary" @click="selectProductType" v-if="JSON.stringify(selectMsg) == '{}'">选择品类
                </el-button>
                <el-card :title="JSON.parse(selectMsg.name).zh" :body-style="{ padding: '0px' }" class="product_card"
                         v-else>
                    <!-- <el-button type="text" icon="el-icon-close" class="clean_btn" @click="cleanProduct()"></el-button> -->
                    <img :src="selectMsg.iconUrl" class="image" width="100" height="100">
                    <div class="card_title">{{ JSON.parse(selectMsg.name).zh }}</div>
                </el-card>
            </el-form-item>

            <el-form-item label="通讯方式：" prop="communication">
                <el-select v-model="form.communication" :popper-append-to-body="false" disabled placeholder="请选择通讯方式">
                    <el-option
                        v-for="item in $message.protocolTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注：">
                <el-input type="textarea" maxlength="200" show-word-limit :rows="3" v-model="form.remarks"></el-input>
            </el-form-item>

            <el-form-item label="产品拟物图：" required :error="err.fileErr">

                <el-card :body-style="{ padding: '0px' }" class="product_card" v-if="form.productImageUrl">
                    <el-button type="text" icon="el-icon-close" class="clean_btn"
                               @click="form.productImageUrl = ''"></el-button>
                    <img :src="form.productImageUrl" class="image" width="100" height="100">
                </el-card>

                <el-upload
                    v-else
                    class="avatar-uploader"
                    :action="$message.uploadImgUrl"
                    :show-file-list="false"
                    :headers="{
                            language: $message.language
                    }"
                    :on-success="handleAvatarSuccess"
                    accept=".png"
                    :on-error="onErr"
                    :data="$message.data"
                    :before-upload="beforeAvatarUpload">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

            </el-form-item>

        </el-form>

        <el-button type="primary" v-if="isDisabled" @click="isDisabled = false" class="edit_btn" size="small">编 辑
        </el-button>

        <div v-else class="edit_btn">
            <el-button @click="isDisabled = true;getMsg()" size="small">取 消</el-button>
            <el-button type="primary" :loading="loading" size="small" @click="save">保 存</el-button>
        </div>

        <!-- 品类列表抽屉 -->
        <add-product-type-drawer></add-product-type-drawer>

    </div>

</template>

<script>

import addProductTypeDrawer from '@/views/product/views/basic/components/addProductTypeDrawer';
import {mapState, mapActions} from "vuex";

export default {

    components: {
        addProductTypeDrawer
    },

    computed: {
        ...mapState('product', ['selectCategoryMsg', 'productMsg'])
    },

    watch: {

        selectCategoryMsg: {
            handler(newVal) {
                this.selectMsg = newVal;
            },
            deep: true
        },

        productMsg: {
            handler(newVal) {
                this.form = {
                    pid: newVal.pid,
                    state: newVal.status,
                    productName: newVal.name,
                    communication: newVal.protocolType,
                    remarks: newVal.remark,
                    productImageUrl: newVal.icon,
                    model: newVal.model
                }
                this.selectMsg = {
                    name: newVal.categoryName,
                    iconUrl: newVal.categoryIconUrl
                }
            },
            deep: true
        }

    },

    data() {

        return {

            err: {
                fileErr: "",
                productTypeErr: ""
            },

            loading: false,

            isDisabled: true,

            formLabelWidth: "120px",

            selectMsg: {},

            height: 50,

            form: {
                pid: "",
                state: 1,
                productName: "",
                productType: "",
                communication: 3,
                remarks: "",
                productImageUrl: "",
                model: ""
            },

            rules: {
                productName: [
                    {required: true, message: "请输入产品名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                productType: [{required: true, message: "请选择产品类别", trigger: "blur"}],
                communication: [{required: true, message: "请选择通讯方式", trigger: "blur"}],
                model: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: '只能输入字母、下划线及数字'},
                ]
            }

        }

    },

    mounted() {
        setTimeout(() => {
            this.height = window.innerHeight - 45 - 130;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.cleanErr();
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    methods: {

        ...mapActions('product', ['modifyProduct', 'getProductDetail']),

        setHeight() {
            this.height = (window.innerHeight - 45 - 130);
        },

        getMsg() {
            this.getProductDetail({
                productCode: this.$route.query.id,
                isRefresh: false
            });
        },

        save() {
            this.$refs.ruleForm.validate((valid) => {
                if (!this.selectMsg.name) {
                    this.err.productTypeErr = "请选择品类";
                    return;
                }
                if (!this.form.productImageUrl) {
                    this.err.fileErr = "请选产品拟物图";
                    return;
                }
                if (valid) {
                    this.cleanErr();
                    this.loading = true;
                    console.log(this.$route.query.id)
                    this.modifyProduct({
                        code: this.$route.query.id,
                        icon: this.form.productImageUrl,
                        model: this.form.model,
                        name: this.form.productName,
                        remark: this.form.remarks,
                    }).then(res => {
                        this.loading = false;
                        this.isDisabled = true;
                        this.getMsg();
                        this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                    }, err => {
                        this.loading = false;
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    });
                }
            })
        },

        cleanErr() {
            this.err = {
                productTypeErr: "",
                fileErr: ""
            }
        },

        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.form.productImageUrl = res.result.filePath;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        beforeAvatarUpload(file) {
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 < 100;
            const isSize = new Promise(function (resolve, reject) {
                let width = 800;
                let height = 800;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传图片尺寸不符合,只能是800*800!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!isPNG) {
                this.$dialog.showMessage('产品拟物图仅支持PNG格式！', this.$config.TOAST_WARNING);
            }
            if (!isLt2M) {
                this.$dialog.showMessage('上传图片大小不能超过 100KB！', this.$config.TOAST_WARNING);
            }
            return isPNG && isLt2M && isSize;
        },

        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        selectProductType() {
            this.$store.commit("product/SET_ADD_PRODUCT_TYPE_DRAWER_VISIBLE", true);
        },
        cleanProduct() {
            this.selectMsg = {};
            this.$store.commit("product/SET_SELECT_CATEGORY_MSG", {});
        }

    }

}
</script>

<style scoped>
.el-card {
    height: 100%;
}

/deep/ .el-card__body {
    overflow-y: auto;
}

/deep/ .el-card__header {
    padding: 15px !important;
    text-align: left;
    font-size: 16px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.el-select, .el-input, .el-textarea {
    width: 300px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.edit_btn {
    margin-left: 120px;
}

.product_card {
    width: 100px;
    text-align: center;
}

.card_title {
    text-align: center;
    padding: 0 5px 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clean_btn {
    margin: 5px 5px -10px 0;
    padding: 0 !important;
    float: right;
    position: relative;
    z-index: 999;
}
</style>
